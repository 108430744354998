import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { BookEventSlotStep } from '@models/events/enums/book-event-slot-step.enum';
import { EventAttendeeTicketOrderStatus } from '@models/events/event-attendee-ticket-order-status.enum';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { EventTicket } from '@models/events/event-ticket.model';
import { Event } from '@models/events/event.model';
import { ProgressIndicatorComponent } from '@modules/shared/components/progress-indicator/progress-indicator.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StripeError } from '@stripe/stripe-js';
import { BookEventSlotChooseSlotComponent } from '../book-event-slot-choose-slot/book-event-slot-choose-slot.component';
import { BookEventSlotChooseTicketComponent } from '../book-event-slot-choose-ticket/book-event-slot-choose-ticket.component';
import { EventSlot } from '@models/events/event-slot.model';
import {
  CreateEventTicketOrderSessionRequest,
  CreateEventTicketOrderSessionTicket,
} from '@models/events/dto/create-event-ticket-order-session.request';
import { EventPaymentComponent } from '../event-payment/event-payment.component';
import { NumberUtils } from '@utils/number-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';

@Component({
  selector: 'app-book-event-slot',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ProgressIndicatorComponent,
    BookEventSlotChooseSlotComponent,
    BookEventSlotChooseTicketComponent,
    EventPaymentComponent,
  ],
  templateUrl: './book-event-slot.component.html',
  styleUrl: './book-event-slot.component.scss',
})
export class BookEventSlotComponent {
  private _existingTicketOrder?: EventAttendeeTicketOrder;
  @Input() isPreview: boolean = false;
  @Input() tickets?: EventTicket[];
  @Input() event?: Event;
  @Input() paymentRedirectStatus?: string;
  @Input() set existingTicketOrder(
    existingTicketOrder: EventAttendeeTicketOrder | undefined,
  ) {
    if (existingTicketOrder) {
      if (this.paymentRedirectStatus === 'failed') {
        existingTicketOrder.status =
          EventAttendeeTicketOrderStatus.REQUIRES_PAYMENT_METHOD;

        this.chosenTicket = this.tickets?.find(
          (ticket) =>
            ticket.id ===
            existingTicketOrder.eventAttendeeTickets[0].eventTicketId,
        );
        this.chosenSlot = this.chosenTicket?.slots?.find(
          (slot) => slot.id === existingTicketOrder.eventSlotId,
        );
        this.orderTickets = existingTicketOrder.eventAttendeeTickets.map(
          (orderTicket) => {
            return {
              eventTicketId: orderTicket.eventTicketId,
              quantity: existingTicketOrder.eventAttendeeTickets.length,
              eventSlotId: this.chosenSlot?.id,
              price: orderTicket.eventTicket?.price,
            };
          },
        );
        this.calculateFullPrice();
        if (this.chosenSlot) {
          this.currentStep = BookEventSlotStep.CHOOSE_SLOT;
        }
        // this.onPaymentFailed();
      } else if (
        existingTicketOrder.isPaidOrProcessing() ||
        this.paymentRedirectStatus === 'succeeded'
      ) {
        if (this.paymentRedirectStatus === 'succeeded') {
          existingTicketOrder.status = EventAttendeeTicketOrderStatus.SUCCEEDED;
        }
        this.ticketOrder = existingTicketOrder;
        if (!this.ticketOrder.eventSlot) {
          const chosenSlot = this.chosenTicket?.slots?.find(
            (slot) => slot.id === existingTicketOrder.eventSlotId,
          );
          if (chosenSlot) {
            this.ticketOrder.eventSlot = chosenSlot;
          }
        }
        this.showSuccessPage = true;
        this.close.emit(this.ticketOrder);
      }
    }
  }
  get existingTicketOrder(): EventAttendeeTicketOrder | undefined {
    return this._existingTicketOrder;
  }

  @Input() isDesktop?: boolean;

  @Output() paymentFailed = new EventEmitter<StripeError | undefined>();
  @Output() close = new EventEmitter<EventAttendeeTicketOrder | undefined>();

  steps: BookEventSlotStep[] = [
    BookEventSlotStep.CHOOSE_TICKET_CATEGORY,
    BookEventSlotStep.CHOOSE_SLOT,
    BookEventSlotStep.PERSONAL_DATA,
    BookEventSlotStep.PAYMENT,
  ];
  currentStep = this.steps[0];
  numberOfSteps = this.steps.length;

  ticketOrder?: EventAttendeeTicketOrder;
  showSuccessPage: boolean = false;

  @Input() chosenTicket?: EventTicket;
  chosenSlot?: EventSlot;

  orderTickets: CreateEventTicketOrderSessionTicket[] = [];

  fullPrice: number = 0;

  currentLang?: string;

  isSessionLoading = false;

  @ViewChild(EventPaymentComponent)
  eventPaymentComponent?: EventPaymentComponent;

  constructor(
    private eventService: EventService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.translateService.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.currentLang = this.translateService.currentLang;
      });
    this.currentLang = this.translateService.currentLang;
  }

  ticketChosen(ticket: EventTicket) {
    this.chosenTicket = ticket;
  }

  onSlotChosen(slot: EventSlot) {
    if (this.chosenSlot && this.chosenSlot.id !== slot.id) {
      this.orderTickets = [];
      this.fullPrice = 0;
    }

    this.chosenSlot = slot;
  }

  addSlotTicket(slot: EventSlot) {
    if (!slot.id || !this.chosenTicket?.id) {
      return;
    }

    const existingTicketIdx = this.orderTickets.findIndex(
      (orderTicket) => orderTicket.eventSlotId === slot.id,
    );

    if (existingTicketIdx !== -1) {
      this.orderTickets[existingTicketIdx].quantity += 1;
    } else {
      this.orderTickets.push({
        eventSlotId: slot.id,
        eventTicketId: this.chosenTicket.id,
        quantity: 1,
        price: this.chosenTicket.price,
      });
    }

    this.calculateFullPrice();
  }

  removeSlotTicket(slot: EventSlot) {
    if (!slot.id || !this.chosenTicket?.id) {
      return;
    }

    const existingTicketIdx = this.orderTickets.findIndex(
      (orderTicket) => orderTicket.eventSlotId === slot.id,
    );

    if (existingTicketIdx !== -1) {
      if (this.orderTickets[existingTicketIdx].quantity > 1) {
        this.orderTickets[existingTicketIdx].quantity -= 1;
      } else {
        this.orderTickets.splice(existingTicketIdx, 1);
      }

      this.calculateFullPrice();
    }
  }

  onClose() {
    this.close.emit();
  }

  nextStep() {
    const currentIndex = this.steps.indexOf(this.currentStep);
    if (currentIndex < this.steps.length - 1) {
      this.currentStep = this.steps[currentIndex + 1];

      if (this.currentStep === BookEventSlotStep.PERSONAL_DATA) {
        this.createTicketOrderSession();
      }
      return;
    }

    this.eventPaymentComponent?.pay();
  }

  previousStep() {
    const currentIndex = this.steps.indexOf(this.currentStep);
    if (currentIndex > 0) {
      this.currentStep = this.steps[currentIndex - 1];
    }
  }

  calculateFullPrice() {
    // let remainingLimit = this.voucherApplied?.limit ?? 0;

    this.fullPrice = this.orderTickets
      .map((orderTicket) => {
        let applicableQuantity = orderTicket.quantity;

        let price = orderTicket.price ?? 0;

        return (price && price > 0 ? price : 0) * applicableQuantity;
      })
      .reduce((a, b) => a + b, 0);

    if (this.ticketOrder) {
      if (!this.ticketOrder.hasPaymentIntent() && this.fullPrice >= 50) {
        // this.createTicketOrderSession();
      } else {
        this.ticketOrder.totalPrice = this.fullPrice;
        this.ticketOrder = new EventAttendeeTicketOrder(this.ticketOrder);
      }
    }
  }

  checkIfNoTickets(): boolean {
    return (
      !this.orderTickets ||
      this.orderTickets.every((ticket) => ticket.quantity === 0)
    );
  }

  onPaymentSuccess(ticketOrder: EventAttendeeTicketOrder) {
    this.ticketOrder = ticketOrder;
    if (!this.ticketOrder.eventSlot && this.chosenSlot) {
      this.ticketOrder.eventSlot = this.chosenSlot;
    }
    this.showSuccessPage = true;
    this.close.emit(this.ticketOrder);
  }
  onPaymentFailed(err?: StripeError) {
    this.paymentFailed.emit(err);
  }

  createOrderTicketsMap(): Map<
    EventTicket,
    CreateEventTicketOrderSessionTicket[]
  > {
    const ticketsMap = new Map<
      EventTicket,
      CreateEventTicketOrderSessionTicket[]
    >();

    if (!this.tickets) {
      return ticketsMap;
    }

    this.orderTickets
      .filter((orderTicket) => orderTicket.quantity > 0) // Filter orderTickets with quantity > 0
      .forEach((orderTicket) => {
        const ticket = this.tickets?.find(
          (t) => t.id === orderTicket.eventTicketId,
        );
        if (ticket) {
          if (!ticketsMap.has(ticket)) {
            ticketsMap.set(ticket, []);
          }
          ticketsMap.get(ticket)!.push(orderTicket);
        }
      });

    return ticketsMap;
  }

  createTicketOrderSession() {
    if (!this.event || this.orderTickets.length < 1 || this.isSessionLoading) {
      return;
    }

    this.isSessionLoading = true;

    this.eventService
      .createEventTicketOrderSession(this.event.id, this.createSessionRequest())
      .subscribe({
        next: (ticketOrder) => {
          this.ticketOrder = ticketOrder;
          if (ticketOrder) {
            // this.ticketQuantityMap = this.ticketOrder?.getTicketQuantityMap();
          }
          this.isSessionLoading = false;
        },
        error: (err) => {
          this.isSessionLoading = false;
          this.notificationService.error(
            this.translateService.instant(
              'APP.TICKETING.ERRORS.COULD_NOT_CREATE_ORDER',
            ),
          );
        },
      });
  }

  createSessionRequest() {
    const request: CreateEventTicketOrderSessionRequest = {
      tickets: this.orderTickets,
      eventSlotId: this.chosenSlot?.id,
    };

    return request;
  }

  get currentStepNumber(): number {
    return this.steps.indexOf(this.currentStep) + 1;
  }

  get isStepValid(): boolean {
    switch (this.currentStep) {
      case BookEventSlotStep.CHOOSE_TICKET_CATEGORY:
        return !!this.chosenTicket && !!this.chosenTicket.id;
      case BookEventSlotStep.CHOOSE_SLOT:
        return !!this.chosenSlot && this.orderTickets.length > 0;
      case BookEventSlotStep.PERSONAL_DATA:
        return !!this.eventPaymentComponent?.isDataValid;
      case BookEventSlotStep.PAYMENT:
        return (
          !!this.eventPaymentComponent &&
          this.eventPaymentComponent.isDataValid &&
          this.eventPaymentComponent.isStripeElementValid &&
          this.eventPaymentComponent.acceptTermsAndConditions &&
          this.eventPaymentComponent.acceptDataPrivacy
        );
    }
  }

  get showBack(): boolean {
    return this.currentStepNumber > 1;
  }

  get nextLabel(): string {
    switch (this.currentStep) {
      case BookEventSlotStep.CHOOSE_SLOT:
        return 'APP.BOOK_EVENT_SLOT.ENTER_PERSONAL_DATA';
      case BookEventSlotStep.PERSONAL_DATA:
        return 'APP.BOOK_EVENT_SLOT.TO_PAYMENT';
      case BookEventSlotStep.PAYMENT:
        return 'APP.BOOK_EVENT_SLOT.COMPLETE_ORDER';
      default:
        return 'APP.NEXT';
    }
  }

  get headerTitle(): string {
    if (this.isDesktop) {
      return 'APP.BOOK_EVENT_SLOT.TITLE';
    }

    switch (this.currentStep) {
      case BookEventSlotStep.CHOOSE_SLOT:
        return 'APP.BOOK_EVENT_SLOT.CHOOSE_SLOT_MOBILE_TITLE';
      case BookEventSlotStep.PERSONAL_DATA:
        return 'APP.BOOK_EVENT_SLOT.PERSONAL_DATA_MOBILE_TITLE';
      case BookEventSlotStep.PAYMENT:
        return 'APP.BOOK_EVENT_SLOT.PAYMENT_MOBILE_TITLE';
      default:
        return 'APP.BOOK_EVENT_SLOT.CHOOSE_TICKET_CATEGORY_MOBILE_TITLE';
    }
  }

  private scrollToTop() {
    setTimeout(() => {
      const contentContainer =
        document.querySelector('.mat-mdc-dialog-surface') || window;
      // reset the view to the top on each step change
      if (contentContainer) {
        contentContainer.scrollTo(0, 0);
      }
    });
  }

  protected readonly BookEventSlotStep = BookEventSlotStep;
  protected readonly NumberUtils = NumberUtils;
}
