<div class="book-event-slot-subheader">
  @if (isDesktop) {
    {{ "APP.BOOK_EVENT_SLOT.CHOOSE_SLOT" | translate }}
  } @else {
    {{ "APP.DATE" | translate }}
  }
</div>
<div class="choose-slot-container">
  @if (isDesktop) {
    <div class="book-event-slot-cards slot-dates-cards">
      @for (slotDate of slotDates; track slotDate) {
        <div
          class="book-event-slot-card"
          [class.selected]="slotDate === chosenSlotDate"
          (click)="chooseSlotDate(slotDate)"
        >
          <div class="card-content card-content-center">
            <div class="card-title">
              {{ getSlotDateDisplay(slotDate) }}
            </div>
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="slot-tabs-swiper">
      <swiper-container
        appSwiper
        [config]="swiperTabsConfig"
        init="false"
        class="tabs"
      >
        @for (slotDate of slotDates; track slotDate) {
          <swiper-slide class="tab">
            <div
              class="book-event-slot-card"
              [class.selected]="slotDate === chosenSlotDate"
              (click)="chooseSlotDate(slotDate)"
            >
              <div class="card-content card-content-center">
                <div class="card-title">
                  {{ getSlotDateDisplay(slotDate) }}
                </div>
              </div>
            </div>
          </swiper-slide>
        }
      </swiper-container>
    </div>
  }
  <div class="choose-slot-arrow">
    <i class="fas fa-right icon-regular"></i>
  </div>
  <div class="slots-cont">
    @if (!isDesktop) {
      <div class="book-event-slot-subheader mb-0">
        {{ "APP.BOOK_EVENT_SLOT.CHOOSE_SLOT_ONLY" | translate }}
      </div>
    }
    <div class="slot-quantity-wrapper">
      @if (!chosenSlotDate) {
        <div class="display">
          <div class="display-main">
            {{ "APP.BOOK_EVENT_SLOT.SELECT_DATE" | translate }}
          </div>
        </div>
      } @else if (!chosenSlot) {
        <div class="display">
          <div class="display-main">
            {{ "APP.BOOK_EVENT_SLOT.NO_SLOTS_SELECTED" | translate }}
          </div>
        </div>
      } @else if (chosenSlot) {
        @if (orderTickets.length) {
          <div class="action-btn" (click)="onRemove()">
            <i class="far fa-minus icon-regular"></i>
          </div>
        } @else {
          <div class="action-btn-placeholder"></div>
        }
        <div class="display">
          @if (orderTickets.length) {
            <div class="display-main">
              {{ orderTickets[0].quantity }}
              {{ "APP.BOOK_EVENT_SLOT.SLOTS_SELECTED" | translate }}
            </div>
          } @else {
            <div class="display">
              <div class="display-main">
                {{ "APP.BOOK_EVENT_SLOT.NO_SLOTS_SELECTED" | translate }}
              </div>
            </div>
          }
          <div class="display-sub">
            {{ chosenSlot.getNumAvailableTickets() }}
            {{ "APP.BOOK_EVENT_SLOT.MAX_CAPACITY" | translate }}
          </div>
        </div>

        <div
          class="action-btn"
          (click)="onAdd()"
          [class.disabled]="!checkSlotAddValid(chosenSlot)"
        >
          <i class="far fa-plus icon-regular"></i>
        </div>
      }
    </div>
    <div class="book-event-slot-cards">
      @for (slot of chosenDateSlots; track slot.id) {
        <div
          class="book-event-slot-card"
          [class.selected]="chosenSlot && slot.id === chosenSlot.id"
          [class.disabled]="slot.getNumAvailableTickets() === 0"
          (click)="chooseSlot(slot)"
        >
          <div class="card-content card-content-center">
            <div class="card-title">
              {{ slot.getTimeRangeFormatted() }}
            </div>
            <div class="card-desc">
              @if (slot.getNumAvailableTickets() === 0) {
                {{ "APP.BOOK_EVENT_SLOT.SOLD_OUT" | translate }}
              } @else {
                {{ slot.getNumAvailableTickets() }}/{{ slot.capacity }}
                {{ "APP.BOOK_EVENT_SLOT.SLOTS_AVAILABLE" | translate }}
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
