<div
  class="book-event-slot-content"
  mat-dialog-content
  [class.choose-ticket-content]="
    currentStep === BookEventSlotStep.CHOOSE_TICKET_CATEGORY
  "
  [class.choose-slot-content]="currentStep === BookEventSlotStep.CHOOSE_SLOT"
  [class.choose-slot-with-amount-content]="
    currentStep === BookEventSlotStep.CHOOSE_SLOT
  "
  [class.payment-content]="
    currentStep === BookEventSlotStep.PERSONAL_DATA ||
    currentStep === BookEventSlotStep.PAYMENT
  "
>
  <div class="dialog-header">
    <div class="dialog-title-with-progress-cont">
      @if (isDesktop) {
        <app-progress-indicator
          [currentStep]="currentStepNumber"
          [numberOfSteps]="numberOfSteps"
          [isCircle]="true"
        >
        </app-progress-indicator>
      } @else if (showBack) {
        <div class="mobile-back" (click)="previousStep()">
          <i class="far fa-chevron-left icon-regular"></i>
        </div>
      }
      <div class="dialog-title-custom">
        {{ headerTitle | translate }}
        @if (!isDesktop && currentStepNumber > 1) {
          ({{ currentStepNumber }}/{{ numberOfSteps }})
        }
      </div>
    </div>

    <div class="close-button-custom">
      <i class="far fa-times icon-regular" (click)="onClose()"></i>
    </div>

    @if (!isDesktop) {
      <app-progress-indicator
        [currentStep]="currentStepNumber"
        [numberOfSteps]="numberOfSteps"
      >
      </app-progress-indicator>
    }
  </div>
  <div class="dialog-body">
    <div>
      @if (tickets) {
        <app-book-event-slot-choose-ticket
          [class.display-none]="
            currentStep !== BookEventSlotStep.CHOOSE_TICKET_CATEGORY
          "
          [tickets]="tickets"
          [chosenTicket]="chosenTicket"
          (ticketChosen)="ticketChosen($event)"
        />
        @if (chosenTicket) {
          <app-book-event-slot-choose-slot
            [class.display-none]="currentStep !== BookEventSlotStep.CHOOSE_SLOT"
            [chosenTicket]="chosenTicket"
            [orderTickets]="orderTickets"
            [chosenSlot]="chosenSlot"
            [isDesktop]="isDesktop"
            (slotChosen)="onSlotChosen($event)"
            (addSlotTicket)="addSlotTicket($event)"
            (removeSlotTicket)="removeSlotTicket($event)"
          />
        }
        <div
          class="generic-get-tickets-wrapper"
          [class.display-none]="
            currentStep !== BookEventSlotStep.PERSONAL_DATA &&
            currentStep !== BookEventSlotStep.PAYMENT
          "
        >
          @if (!showSuccessPage) {
            @if (!isDesktop && event && chosenSlot) {
              <div class="inline-overview-cont">
                <div class="inline-event-info">
                  <div class="name">
                    {{ event.name }} | {{ chosenTicket?.name }}
                  </div>
                  <div class="date">
                    {{ chosenSlot.getSlotDateDisplay(currentLang) }}
                  </div>
                </div>
                <div class="inline-amount-info">
                  <div class="amount-total">
                    {{
                      NumberUtils.roundToUpTo2Decimals(fullPrice / 100.0)
                    }}&euro;
                  </div>
                  @if (
                    orderTickets &&
                    orderTickets.length > 0 &&
                    orderTickets[0].price
                  ) {
                    <div class="amount-calc">
                      {{ orderTickets[0].quantity }}x{{
                        NumberUtils.roundToUpTo2DecimalsKeepWhole(
                          orderTickets[0].price / 100.0
                        )
                      }}&euro;
                    </div>
                  }
                </div>
              </div>
            }
            <div class="item payment">
              <ng-container *ngTemplateOutlet="payment"></ng-container>
            </div>
          }
        </div>
      }
    </div>
  </div>
  <div
    mat-dialog-actions
    class="dialog-actions"
    [class.column-actions]="
      currentStep === BookEventSlotStep.PERSONAL_DATA ||
      currentStep === BookEventSlotStep.PAYMENT
    "
    [class.actions-with-amount]="currentStep === BookEventSlotStep.CHOOSE_SLOT"
  >
    @if (currentStep === BookEventSlotStep.CHOOSE_SLOT) {
      <div class="order-amount">
        <div class="amount-desc">
          {{ "APP.BOOK_EVENT_SLOT.TO_PAY" | translate }}
        </div>
        <div class="amount">
          {{ NumberUtils.roundToUpTo2Decimals(fullPrice / 100.0) }}&euro;
        </div>
      </div>
      <div class="dialog-actions-btns">
        @if (showBack && isDesktop) {
          <button
            class="btn btn-primary-text pop-color"
            (click)="previousStep()"
          >
            {{ "APP.GO_BACK" | translate }}
          </button>
        }

        <button
          class="btn btn-primary pop-color"
          [disabled]="!isStepValid"
          (click)="nextStep()"
        >
          {{ nextLabel | translate }}
        </button>
      </div>
    } @else {
      @if (showBack && isDesktop) {
        <button class="btn btn-primary-text pop-color" (click)="previousStep()">
          {{ "APP.GO_BACK" | translate }}
        </button>
      }

      <button
        class="btn btn-primary pop-color"
        [disabled]="!isStepValid"
        (click)="nextStep()"
      >
        {{ nextLabel | translate }}
      </button>
    }
  </div>
</div>
@if (
  (currentStep === BookEventSlotStep.PERSONAL_DATA ||
    currentStep === BookEventSlotStep.PAYMENT) &&
  !showSuccessPage
) {
  <div class="item overview-card">
    <div class="overview-card-content">
      <!--Event info-->
      <ng-container *ngTemplateOutlet="eventInfo"></ng-container>

      <!--Total price section-->
      <ng-container *ngTemplateOutlet="orderOverview"></ng-container>
    </div>
  </div>
}

<ng-template #eventInfo>
  @if (event && chosenSlot) {
    <div class="event-info-wrapper">
      <div class="event-image-wrapper">
        <div class="overlay">
          <img
            [src]="event.getEncodedMainImage(true)"
            class="key-visual-img"
            alt="Key Visual"
          />
        </div>
        <img
          [src]="event.getEncodedMainImage(true)"
          class="key-visual-img logo-image"
          alt="Key Visual"
        />
      </div>
      <div class="event-info">
        <div class="name">{{ event.name }} | {{ chosenTicket?.name }}</div>
        <div class="date-address">
          <div class="date">
            {{ chosenSlot.getSlotDateDisplay(currentLang) }}
          </div>
          <div class="location-wrapper">
            <div class="icon">
              <i class="fal fa-map-marker-alt"></i>
            </div>
            <div class="item">
              <div class="item-title">
                {{ event.getAddressDisplayNamePrimary() }}
              </div>
              <div class="value">
                {{ event.getAddressDisplayNameSecondary() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</ng-template>

<ng-template #orderOverview>
  <div class="selection-footer">
    @if (createOrderTicketsMap()) {
      @if (!checkIfNoTickets()) {
        <div class="divider"></div>
      }
      @for (ticket of createOrderTicketsMap() | keyvalue; track $index) {
        <div class="order-item-wrapper">
          <div class="item">
            <div>{{ ticket.value[0].quantity }}x {{ ticket.key.name }}</div>
            <!-- <div class="date">{{ attendeeTicket.ticket.eventTicket?.day | date: 'EEE. dd. MMMM' }}</div>-->
          </div>

          <div class="price">
            {{ ticket.value[0].quantity }}x{{
              NumberUtils.roundToUpTo2DecimalsKeepWhole(
                ticket.key.price / 100.0
              )
            }}&euro;
          </div>
        </div>
      }
    }

    <div class="total order-amount">
      <div class="amount-desc">
        {{ "APP.BOOK_EVENT_SLOT.TO_PAY" | translate }}
      </div>
      <div class="amount">
        {{ NumberUtils.roundToUpTo2Decimals(fullPrice / 100.0) }}&euro;
      </div>
    </div>
  </div>
</ng-template>

<ng-template #payment>
  <app-event-payment
    [isPreview]="isPreview"
    [event]="event"
    [chosenSlotTicket]="chosenTicket"
    [ticketOrder]="ticketOrder"
    [isGeneric]="true"
    [isBookASlot]="true"
    [bookEventSlotStep]="currentStep"
    [canPay]="!checkIfNoTickets()"
    (nextStep)="onPaymentSuccess($event)"
    (paymentFailed)="onPaymentFailed($event)"
  />
  <!-- <app-event-payment
    [isPreview]="isPreview"
    [event]="event"
    [ticketOrder]="ticketOrder"
    [mainImage]="event?.getEncodedMainImage(true)"
    [voucher]="voucherApplied"
    [isGeneric]="true"
    [canPay]="!checkIfNoTickets()"
    [updatedSessionRequest]="updatedSessionRequest"
    (nextStep)="onPaymentSuccess($event)"
    (paymentFailed)="onPaymentFailed($event)"
  /> -->
</ng-template>
