@if (isBookASlot) {
  <div
    class="ticket-wrapper booking-slots-wrapper"
    [class.generic-dialog]="isGeneric"
  >
    <div class="generic-buying-tickets-cont">
      <app-book-event-slot
        [tickets]="tickets"
        [event]="event"
        [paymentRedirectStatus]="redirectStatus"
        [existingTicketOrder]="ticketOrder"
        [chosenTicket]="chosenTicket"
        [isDesktop]="isDesktop"
        (paymentFailed)="onPaymentFailed($event)"
        (close)="onCloseFromBookEventSlot($event)"
      />
    </div>
  </div>
} @else {
  <div
    class="ticket-wrapper"
    [class.age-confirmation]="step === 1 || step === 4"
    [class.select-tickets]="step === 2"
    [class.popup-wrapper]="showPopupInfo"
    [class.generic-dialog]="isGeneric"
  >
    @if (!isGeneric) {
      @if (step === 1) {
        <div class="age-confirmation-wrapper">
          @if (step === 1) {
            <img
              [src]="mainImage"
              class="key-visual-img logo-image"
              alt="Key Visual"
            />
            <div class="text">
              {{ "APP.TICKETING.ARE_YOU_OLD_ENOUGH" | translate }}
            </div>
            <div class="link">
              <a
                href="https://eventpage.ai/datenschutzerklaerung/"
                target="_blank"
                >{{ "APP.PRIVACY_POLICY" | translate }}</a
              >
            </div>
            <div
              class="action-buttons"
              [class.square-style]="
                event?.type === EventType.ADVANCED_ONE_COLUMN
              "
            >
              <button class="btn btn-action" (click)="nextStep()">
                {{ "APP.TICKETING.OLDER_THAN_18" | translate }}
              </button>
              <button class="btn btn-action outline" (click)="onClose()">
                {{ "APP.TICKETING.YOUNGER_THAN_18" | translate }}
              </button>
            </div>
          }
        </div>
      } @else {
        @if (showPopupInfo) {
          <div class="info-cont">
            @if (
              showPopupInfoType === ShowPopupInfoType.LEAVE_SESSION ||
              showPopupInfoType === ShowPopupInfoType.LEAVE_SESSION_BACK
            ) {
              <div class="info-title">
                {{ "APP.TICKETING.LEAVE_SESSION.TITLE" | translate }}
              </div>
              <div class="info-message">
                {{ "APP.TICKETING.LEAVE_SESSION.MESSAGE" | translate }}
              </div>
              <div class="info-actions">
                <div class="btn btn-primary" (click)="stay()">
                  {{ "APP.TICKETING.LEAVE_SESSION.STAY" | translate }}
                </div>
                <div class="btn btn-primary-outline" (click)="leaveSession()">
                  {{ "APP.TICKETING.LEAVE_SESSION.LEAVE" | translate }}
                </div>
              </div>
            } @else if (showPopupInfoType === ShowPopupInfoType.TIME_EXPIRED) {
              <div class="info-title">
                {{ "APP.TICKETING.TIME_EXPIRED.TITLE" | translate }}
              </div>
              <div class="info-message">
                {{ "APP.TICKETING.TIME_EXPIRED.MESSAGE" | translate }}
              </div>
              <div class="info-actions">
                <div class="text-btn" (click)="ticketsUnavailableRefresh(true)">
                  {{ "APP.TICKETING.TIME_EXPIRED.BACK_TO_TICKETS" | translate }}
                </div>
              </div>
            } @else if (
              showPopupInfoType === ShowPopupInfoType.TICKETS_UNAVAILABLE
            ) {
              <div class="info-title">
                {{ "APP.TICKETING.TICKETS_UNAVAILABLE.TITLE" | translate }}
              </div>
              <div class="info-message">
                {{ "APP.TICKETING.TICKETS_UNAVAILABLE.MESSAGE" | translate }}
              </div>
              <div class="info-actions">
                <div class="text-btn" (click)="ticketsUnavailableRefresh()">
                  {{
                    "APP.TICKETING.TICKETS_UNAVAILABLE.BACK_TO_TICKETS"
                      | translate
                  }}
                </div>
              </div>
            }
          </div>
        } @else {
          @if (step !== 4) {
            <div class="payment-header">
              <div class="event-date-info">
                @if (isDesktop && step !== 3) {
                  @if (mainImage) {
                    <img
                      [src]="mainImage"
                      class="key-visual-img logo-image"
                      alt="Key Visual"
                    />
                  }
                } @else {
                  <div class="title">{{ event?.name }}</div>
                }

                <div class="date-time">
                  {{ formatEventDate(event?.startDate, event?.endDate) }}
                </div>
              </div>
              <div class="action-wrapper">
                <div class="action" (click)="step === 3 && previousStep()">
                  @if (step === 3) {
                    <i class="fal fa-chevron-circle-left"></i>
                    @if (isDesktop) {
                      <span class="remaining-time"
                        >{{
                          "APP.TICKETING.REMAINING_TIME" | translate | uppercase
                        }}
                        {{
                          checkoutSessionTimeRemaining$ | async | date: "mm:ss"
                        }}
                      </span>
                    }
                  }
                </div>
                @if (!isDesktop && step === 3) {
                  <span class="remaining-time"
                    >{{
                      "APP.TICKETING.REMAINING_TIME" | translate | uppercase
                    }}
                    {{ checkoutSessionTimeRemaining$ | async | date: "mm:ss" }}
                  </span>
                }
                <div class="action" (click)="onClose()">
                  <i class="fal fa-times-circle"></i>
                </div>
              </div>
            </div>
          }
          @if (step === 2) {
            <app-event-tickets-selection
              [tickets]="tickets"
              [event]="event"
              [mainImage]="mainImage"
              [checkoutCreationLoading]="checkoutCreationLoading"
              (nextStep)="createTicketOrderSession($event)"
              (applyVoucher)="setVoucher($event)"
            />
          }

          @if (step === 3) {
            <app-event-payment
              [event]="event"
              [ticketOrder]="ticketOrder"
              [mainImage]="mainImage"
              [voucher]="voucherApplied"
              (nextStep)="onPaymentSuccess($event)"
              (paymentFailed)="onPaymentFailed($event)"
            />
          }

          @if (step == 4) {
            <app-ticket-payment-success
              [ticketOrder]="ticketOrder"
              [mainImage]="mainImage"
              [event]="event"
              (onClose)="onClose()"
            />
          }
        }
      }
    } @else {
      @if (tickets) {
        <div class="payment-generic-header">
          <div class="title">{{ "APP.EVENT.GET_TICKETS" | translate }}</div>
          <div class="action" (click)="onClose()">
            <i class="fal fa-times-circle"></i>
          </div>
        </div>
        <div class="generic-buying-tickets-cont">
          <app-event-generic-buying-tickets
            [tickets]="tickets"
            [event]="event"
            [paymentRedirectStatus]="redirectStatus"
            [existingTicketOrder]="ticketOrder"
            [mainImage]="mainImage"
            (paymentFailed)="onPaymentFailed($event)"
          />
        </div>
      }
    }
  </div>
}
