<div class="ticket-payment-success-dialog" mat-dialog-content>
  <div class="dialog-header">
    <div class="dialog-title-custom">
      {{ "APP.BOOK_EVENT_SLOT.PAYMENT_SUCCESS_TITLE" | translate }}
    </div>
    <div class="close-button-custom">
      <i class="far fa-times icon-regular" (click)="close()"></i>
    </div>
  </div>
  <div class="dialog-body">
    <div>
      @if (event) {
        <div class="checkout-wrapper" [class.generic-wrapper]="isGeneric">
          <div class="content">
            <div class="order">
              <div
                class="image"
                [ngStyle]="{
                  'background-image': 'url(' + mainImage + ')',
                  'background-color': visual?.backgroundColor
                }"
              >
                @if (event.isMainPictureVideo()) {
                  <div class="background-video-cont">
                    <video
                      class="key-visual-1"
                      [muted]="'muted'"
                      autoplay
                      playsinline
                      loop
                    >
                      <source [src]="event.mainPictureUrl" type="video/mp4" />
                    </video>
                  </div>
                }
              </div>
              @if (isDesktop) {
                <ng-container *ngTemplateOutlet="orderInfo"></ng-container>
              } @else {
                <div class="order-info">
                  <div class="event-info">
                    <div class="name">
                      {{ event.name }}
                    </div>
                    <div class="date-address">
                      <div class="date">
                        {{
                          ticketOrder?.eventSlot?.getSlotDateDisplay(
                            currentLang
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          @if (!isDesktop) {
            <ng-container *ngTemplateOutlet="orderInfo"></ng-container>

            <app-footer
              class="after-footer"
              [showLogo]="true"
              [bodyColor]="'var(--text-tertiary)'"
              [backgroundColor]="'var(--bg)'"
              [includePoweredBy]="true"
              [showOptions]="false"
              [isGenericFooter]="true"
              [isBookASlotFooter]="true"
            ></app-footer>
          }
        </div>
      }
    </div>
  </div>
  @if (isDesktop) {
    <app-footer
      class="after-footer"
      [showLogo]="true"
      [bodyColor]="'var(--text-tertiary)'"
      [backgroundColor]="'var(--bg-secondary)'"
      [includePoweredBy]="true"
      [showOptions]="false"
      [isGenericFooter]="true"
      [isBookASlotFooter]="true"
    ></app-footer>
  } @else {
    <div mat-dialog-actions class="dialog-actions">
      <div class="download-info">
        @if (ticketOrder?.isProcessing()) {
          {{ "APP.EVENT_PAYMENT.PROCESSING_INSTRUCTIONS" | translate }}
        } @else {
          {{ "APP.BOOK_EVENT_SLOT.TICKET_MANAGEMENT_INFO" | translate }}
        }
      </div>
      <div class="action">
        @if (ticketOrder?.isProcessing()) {
          <button class="btn btn-primary-outline processing-btn">
            <div class="loader"></div>
            {{ "APP.EVENT_PAYMENT.PAYMENT_PROCESSING" | translate }}
          </button>
        } @else {
          <button class="btn btn-primary-outline" (click)="downloadTicketPdf()">
            {{ "APP.EVENT_PAYMENT.DOWNLOAD_TICKETS" | translate }}
          </button>
        }
      </div>
    </div>
  }
</div>

<ng-template #eventInfo> </ng-template>

<ng-template #orderInfo>
  @if (event) {
    <div class="order-info">
      <div class="event-info">
        @if (isDesktop) {
          <div class="name">
            {{ event.name }}
          </div>
        }
        <div class="date-address">
          @if (isDesktop) {
            <div class="date">
              {{ ticketOrder?.eventSlot?.getSlotDateDisplay(currentLang) }}
            </div>
          }
          <div class="location-wrapper">
            <div class="icon">
              <i class="fal fa-map-marker-alt"></i>
            </div>
            <div class="item">
              <div class="item-title">
                {{ event.getAddressDisplayNamePrimary() }}
              </div>
              <div class="value">
                {{ event.getAddressDisplayNameSecondary() }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order-overview">
        @if (ticketOrder) {
          <div class="divider"></div>
          @for (
            attendeeTicket of ticketOrder.getTicketQuantityMap();
            track attendeeTicket.ticket.eventTicket
          ) {
            <div class="order-item-wrapper">
              <div class="item">
                <div>
                  {{ attendeeTicket.quantity }}x
                  {{ attendeeTicket.ticket.eventTicket?.name }}
                </div>
                <!-- <div class="date">{{ attendeeTicket.ticket.eventTicket?.day | date: 'EEE. dd. MMMM' }}</div>-->
              </div>

              <div class="price">
                {{ attendeeTicket.quantity }}x{{
                  NumberUtils.roundToUpTo2DecimalsKeepWhole(
                    attendeeTicket.ticket.price / 100.0
                  )
                }}&euro;
              </div>
            </div>
          }

          <div class="total order-amount">
            <div class="amount-desc">
              {{ "APP.EVENT_PAYMENT.TOTAL" | translate }}:
            </div>
            <div class="amount">
              {{ ticketOrder.getDisplayTotalPrice() }}
            </div>
          </div>
        }
      </div>

      @if (isDesktop) {
        <div class="ticket-actions">
          <div class="download-info">
            @if (ticketOrder?.isProcessing()) {
              {{ "APP.EVENT_PAYMENT.PROCESSING_INSTRUCTIONS" | translate }}
            } @else {
              {{ "APP.BOOK_EVENT_SLOT.TICKET_MANAGEMENT_INFO" | translate }}
            }
          </div>

          <div class="action">
            @if (ticketOrder?.isProcessing()) {
              <button class="btn btn-primary-outline processing-btn">
                <div class="loader"></div>
                {{ "APP.EVENT_PAYMENT.PAYMENT_PROCESSING" | translate }}
              </button>
            } @else {
              <button
                class="btn btn-primary-outline"
                (click)="downloadTicketPdf()"
              >
                {{ "APP.EVENT_PAYMENT.DOWNLOAD_TICKETS" | translate }}
              </button>
            }
          </div>
        </div>
      }
    </div>
  }
</ng-template>
