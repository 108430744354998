<div class="book-event-slot-subheader">
  {{ "APP.BOOK_EVENT_SLOT.CHOOSE_TICKET_CATEGORY" | translate }}
</div>
<div class="book-event-slot-cards">
  @for (ticket of tickets; track ticket.id) {
    <div
      class="book-event-slot-card"
      [class.selected]="chosenTicket && chosenTicket.id === ticket.id"
      (click)="chooseTicket(ticket)"
    >
      <div class="card-content">
        <div class="card-title">
          {{ ticket.name }} | {{ ticket.getDisplayPrice() }}/{{
            "APP.BOOK_EVENT_SLOT.SLOT" | translate
          }}
        </div>
        <!-- <div class="card-desc"></div> -->
      </div>
      @if (ticket.landingPageDescription) {
        <div
          class="card-info"
          [matTooltip]="ticket.landingPageDescription"
          [matTooltipPosition]="'above'"
          matTooltipClass="menu-item-tooltip"
        >
          <i class="far fa-circle-info icon-regular"></i>
        </div>
      }
    </div>
  }
</div>
