import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EventTicket } from '@models/events/event-ticket.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-book-event-slot-choose-ticket',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatTooltipModule],
  templateUrl: './book-event-slot-choose-ticket.component.html',
  styleUrl: './book-event-slot-choose-ticket.component.scss',
})
export class BookEventSlotChooseTicketComponent {
  @Input() tickets?: EventTicket[];
  @Input() chosenTicket?: EventTicket;

  @Output() ticketChosen = new EventEmitter<EventTicket>();

  chooseTicket(ticket: EventTicket) {
    this.chosenTicket = ticket;
    this.ticketChosen.emit(ticket);
  }
}
