import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CreateEventTicketOrderSessionTicket } from '@models/events/dto/create-event-ticket-order-session.request';
import { EventSlot } from '@models/events/event-slot.model';
import { EventTicket } from '@models/events/event-ticket.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SwiperDirective } from 'app/directives/swiper.directive';
import moment from 'moment-timezone';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-book-event-slot-choose-slot',
  standalone: true,
  imports: [CommonModule, TranslateModule, SwiperDirective],
  templateUrl: './book-event-slot-choose-slot.component.html',
  styleUrl: './book-event-slot-choose-slot.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BookEventSlotChooseSlotComponent {
  private _chosenTicket!: EventTicket;
  @Input() set chosenTicket(chosenTicket: EventTicket) {
    this._chosenTicket = chosenTicket;
    this.groupedSlots = this.chosenTicket.getSlotsByDate();
    this.slotDates = Array.from(this.groupedSlots.keys());

    this.chosenDateSlots = undefined;
    this.chosenSlotDate = undefined;
    this.chosenSlot = undefined;
    this.slotChosen.emit(this.chosenSlot);
  }
  get chosenTicket(): EventTicket {
    return this._chosenTicket;
  }

  @Input() orderTickets: CreateEventTicketOrderSessionTicket[] = [];

  groupedSlots?: Map<string, EventSlot[]>;
  slotDates?: string[];
  chosenDateSlots?: EventSlot[];

  chosenSlotDate?: string;
  private _chosenSlot?: EventSlot;
  @Input() set chosenSlot(chosenSlot: EventSlot | undefined) {
    this._chosenSlot = chosenSlot;
    if (chosenSlot) {
      this.chooseSlotDate(
        chosenSlot.getStartDateTzMoment().format('YYYY-MM-DD'),
      );
    }
  }
  get chosenSlot(): EventSlot | undefined {
    return this._chosenSlot;
  }

  @Input() isDesktop?: boolean;

  @Output() slotChosen = new EventEmitter<EventSlot>();
  @Output() addSlotTicket = new EventEmitter<EventSlot>();
  @Output() removeSlotTicket = new EventEmitter<EventSlot>();

  swiperTabsConfig: SwiperOptions = {
    slidesPerView: 'auto',
    speed: 1000,

    spaceBetween: 16,
    injectStyles: [
      `
          :host .swiper { overflow: visible;}
        `,
    ],
  };

  constructor(private translateService: TranslateService) {}

  chooseSlotDate(date: string) {
    this.chosenSlotDate = date;
    this.chosenDateSlots = this.groupedSlots?.get(this.chosenSlotDate);
  }

  chooseSlot(slot: EventSlot) {
    if (slot.getNumAvailableTickets() === 0) {
      return;
    }

    this.chosenSlot = slot;
    this.slotChosen.emit(this.chosenSlot);
  }

  onAdd() {
    if (this.chosenSlot) {
      if (!this.chosenSlot.id) {
        return;
      }
      if (this.checkSlotAddValid(this.chosenSlot)) {
        this.addSlotTicket.emit(this.chosenSlot);
      }
    }
  }

  onRemove() {
    this.removeSlotTicket.emit(this.chosenSlot);
  }

  checkSlotAddValid(slot: EventSlot): boolean {
    const existingTicketIdx = this.orderTickets.findIndex(
      (orderTicket) => orderTicket.eventSlotId === slot.id,
    );
    let currentQuantity = 0;
    if (existingTicketIdx !== -1) {
      /* if (ticket.minAmountPerOrder && this.appliedDiscount) {
          currentQuantity =
            this.orderTickets[existingTicketIdx].quantity +
            ticket.minAmountPerOrder;
        } else {
          currentQuantity = this.orderTickets[existingTicketIdx].quantity + 1;
        }  */
      currentQuantity = this.orderTickets[existingTicketIdx].quantity + 1;
    } else {
      // if (ticket.minAmountPerOrder) {
      //   currentQuantity = ticket.minAmountPerOrder;
      // } else {
      //   currentQuantity = 1;
      // }
      currentQuantity = 1;
    }

    const numAvailable = slot.getNumAvailableTickets();

    if (currentQuantity > numAvailable) {
      return false;
    }

    return true;
  }

  getSlotDateDisplay(slotDate: string) {
    const toFormat = moment(slotDate);
    toFormat.locale(this.translateService.currentLang);

    return toFormat.format('ddd, DD.MM.YYYY');
  }
}
